import React from "react";
import { shape, string, any, arrayOf } from "prop-types";
import PageMeta from "@insightfulscience/smart-react/PageMeta";
import { BRAND_NAMES } from "@insightfulscience/contentful/constants";
import thumbnailTdo from "@insightfulscience/contentful/dtos/thumbnail.tdo";
import { useLocale } from "@insightfulscience/smart-react/i18";
import { file } from "@insightfulscience/contentful/utils/propTypes";
import CopyBlock from "@insightfulscience/contentful/Apps/components/CopyBlock";
import renderBlockByType from "@insightfulscience/contentful/utils/renderBlockByType";
import LandingSimpleSectionStripped from "@insightfulscience/atomic-react/LandingElement/SimpleSectionStripped";
import Line from "@insightfulscience/atomic-react/Line";
import VisualCopyBlock from "@insightfulscience/contentful/Apps/components/VisualCopyBlock";
import TestimonialsBlock from "../components/TestimonialBlock";
import LinksBlock from "../components/BlockLinks/BlockLinks";
import HeroBlock from "./components/HeroBlock";
import SiteLayout from "../../../SiteLayout";
import SectionTryForFree from "../../../SectionTryForFree";
import BiologicsHeader from "../../../BiologicsHeader";
import SiteHeader from "../../../SiteHeader";

const ProductPage = ({ product, pageProps }) => {
	const [t] = useLocale("series");
	const { siteDomain, brandCode, components } = pageProps;
	const {
		metaTitle,
		metaDescription,
		metaCanonical,
		slug,
		thumbnail,
		referenceHero,
		referenceLinks,
		referenceVisualCopies,
		referenceAllFeatures,
		referenceExploreMore,
		referenceTestimonial,
		biologicsPage,
	} = product;
	const brandName = BRAND_NAMES[brandCode];
	const thumbnailUrl = thumbnailTdo(thumbnail);
	return (
		<SiteLayout HeaderComponent={biologicsPage ? BiologicsHeader : SiteHeader}>
			<PageMeta title={`${metaTitle} - ${brandName}`} description={metaDescription}>
				<PageMeta.OGP
					locale={t("META_LOCALE")}
					type={t("META_TYPE")}
					siteName={t("META_SITE_NAME")}
					title={metaTitle ? `${metaTitle} - ${brandName}` : null}
					description={metaDescription || null}
					url={`${siteDomain}/series/${slug}`}
					image={thumbnailUrl || t("META_IMAGE")}
					imageSecureUrl={thumbnailUrl || t("META_IMAGE_SECURE")}
					isImageBaseUrl={!thumbnailUrl}
				/>
				<PageMeta.Twitter
					card={t("META_TWITTER_CARD")}
					site={t("META_TWITTER_SITE")}
					creator={t("META_TWITTER_CREATOR")}
					title={metaTitle ? `${metaTitle} - ${brandName}` : null}
					description={metaDescription || null}
					image={thumbnailUrl || t("META_TWITTER_IMAGE")}
					isImageBaseUrl={!thumbnailUrl}
				/>
				<PageMeta.Canonical href={metaCanonical} />
			</PageMeta>
			{referenceHero && <HeroBlock {...referenceHero.fields} biologicsPage={biologicsPage} badge />}
			<Line />
			{referenceLinks && <LinksBlock {...referenceLinks.fields} />}
			{(referenceVisualCopies || []).map(({ sys: { id }, fields }) => (
				<VisualCopyBlock
					isImageFrame={false}
					ctaStyleType="primary"
					key={id}
					{...fields}
					standardTitle={false}
				/>
			))}
			{referenceAllFeatures && <CopyBlock {...referenceAllFeatures.fields} />}
			{referenceExploreMore && (
				<LandingSimpleSectionStripped>
					{referenceExploreMore.map(renderBlockByType(slug, components))}
				</LandingSimpleSectionStripped>
			)}
			{referenceTestimonial && <TestimonialsBlock {...referenceTestimonial.fields} />}
			<SectionTryForFree />
		</SiteLayout>
	);
};

ProductPage.propTypes = {
	product: shape({
		metaTitle: string,
		metaDescription: string,
		metaCanonical: string,
		slug: string.isRequired,
		thumbnail: file,
		referenceHero: shape({
			fields: shape(HeroBlock.propTypes),
		}),
		referenceLinks: shape({
			fields: shape(LinksBlock.propTypes),
		}),
		referenceVisualCopies: arrayOf(
			shape({
				fields: shape(VisualCopyBlock.propTypes),
			}),
		),
		referenceAllFeatures: shape({
			fields: shape(CopyBlock.propTypes),
		}),
		referenceExploreMore: shape({
			fields: shape(CopyBlock.propTypes),
		}),
		referenceTestimonial: shape({
			fields: shape(TestimonialsBlock.propTypes),
		}),
	}),
	pageProps: shape({
		resources: any, // eslint-disable-line react/forbid-prop-types,
		siteDomain: string,
		brandCode: string,
		backLink: shape({
			title: string.isRequired,
			href: string.isRequired,
		}),
	}),
};

export default ProductPage;
