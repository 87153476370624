export const groupByHeading = (content, numberOfBlocks) => {
	const headTypes = ["heading-1", "heading-2", "heading-3", "heading-4", "heading-5", "heading-6"];
	let column = [];
	let numInColumn = 1;

	return content.reduce((prev, item, key, arr) => {
		if (column.length && headTypes.includes(item.nodeType) && numInColumn === 1) {
			prev.push(column);
			column = [];
		}
		column.push(item);
		if (arr.length - 1 === key) prev.push(column);
		if (headTypes.includes(item.nodeType)) {
			numInColumn = numInColumn === numberOfBlocks ? 1 : numInColumn + 1;
		}
		return prev;
	}, []);
};
