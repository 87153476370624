import React from "react";
import { arrayOf, bool, shape, string } from "prop-types";
import PageNavbar from "@insightfulscience/atomic-react/PageNavbar";
import PageNavbarItem from "@insightfulscience/atomic-react/PageNavbar/Item";
import PageNavbarLink from "@insightfulscience/atomic-react/PageNavbar/Link";

const BlockLinks = ({ linkReference, stickyToC = false }) => {
	return (
		<PageNavbar bgColor="white" sticky={stickyToC}>
			{linkReference.map(({ fields }) => (
				<PageNavbarItem key={fields.linkUrl}>
					<PageNavbarLink href={fields.linkUrl} className="px-6">
						{fields.linkText}
					</PageNavbarLink>
				</PageNavbarItem>
			))}
		</PageNavbar>
	);
};

BlockLinks.propTypes = {
	stickyToC: bool,
	linkReference: arrayOf(
		shape({
			fields: shape({
				linkText: string.isRequired,
				linkUrl: string.isRequired,
			}),
		}),
	),
};

export default BlockLinks;
