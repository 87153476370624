import React from "react";
import Image from "@insightfulscience/atomic-react/Image";
import { string } from "prop-types";
import {
	TestimonialColInfo,
	TestimonialColLogo,
} from "@insightfulscience/atomic-react/Testimonial/Grid";
import TestimonialBlockQuote from "@insightfulscience/atomic-react/Testimonial/BlockQuote";
import Testimonial from "@insightfulscience/atomic-react/Testimonial";
import { file } from "@insightfulscience/contentful/utils/propTypes";

export const TestimonialItem = ({ logo, position, quoteAuthor, quoteText }) => {
	const isImgExist = logo?.fields?.file?.url;
	const infoColumnWidth = isImgExist ? { md: 6, lg: 6 } : { md: 12, lg: 12 };
	return (
		<Testimonial mx spacing={{ mb: 4, sm: { px: 2 } }}>
			<TestimonialColInfo
				md={infoColumnWidth.md}
				lg={infoColumnWidth.lg}
				spacing={{
					md: {
						pl: 0,
						pr: 12,
						py: 10,
					},
				}}
			>
				<TestimonialBlockQuote author={quoteAuthor} company={position}>
					{quoteText}
				</TestimonialBlockQuote>
			</TestimonialColInfo>
			{isImgExist && (
				<TestimonialColLogo md={6} lg={6} spacing={{ sm: { mb: 8 } }} sx={{ textAlign: "center" }}>
					<Image src={logo?.fields?.file?.url} isBaseUrl={false} />
				</TestimonialColLogo>
			)}
		</Testimonial>
	);
};

TestimonialItem.propTypes = {
	logo: file,
	position: string,
	quoteAuthor: string,
	quoteText: string,
};

export default TestimonialItem;
