/* eslint-disable react/no-array-index-key */
import React from "react";
import { arrayOf, shape, string } from "prop-types";
import { Container } from "@insightfulscience/atomic-react/Grid";
import useTestimonial from "@insightfulscience/atomic-react/Testimonial/hooks/useTestimonial";
import TestimonialTabs from "@insightfulscience/atomic-react/Testimonial/Tabs";
import TestimonialTabsItem from "@insightfulscience/atomic-react/Testimonial/Tab";

import Template from "@insightfulscience/contentful/Apps/components/Template";
import { file } from "@insightfulscience/contentful/utils/propTypes";
import Testimonial from "./Testimonial";

const TestimonialsBlock = ({ blockTitle, blockSubtitle, quoteReference = [], internalSlug }) => {
	const [active, activeIndex, setActiveIndex] = useTestimonial(quoteReference);

	return (
		<Template title={blockTitle} subtitle={blockSubtitle} anchor={internalSlug}>
			{quoteReference.length > 0 && (
				<>
					{active && (
						<Container spacing={{ sm: { px: 0 }, p: 0 }}>
							<Testimonial {...active.fields} />
						</Container>
					)}
					<Container>
						<TestimonialTabs>
							{quoteReference.map(({ sys: { id }, fields: { logo } }, index) => (
								<TestimonialTabsItem
									key={`${id}_${index}`}
									logoImg={logo?.fields?.file?.url}
									isBaseUrl={false}
									active={activeIndex === index}
									onClick={() => setActiveIndex(index)}
									invert="white"
								/>
							))}
						</TestimonialTabs>
					</Container>
				</>
			)}
		</Template>
	);
};

TestimonialsBlock.propTypes = {
	internalSlug: string.isRequired,
	blockTitle: string,
	blockSubtitle: string,
	quoteReference: arrayOf(
		shape({
			sys: shape({
				id: string,
			}),
			fields: shape({
				logo: file,
				position: string,
				quoteAuthor: string,
				quoteText: string,
			}),
		}),
	),
};

export default TestimonialsBlock;
