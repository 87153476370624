import React from "react";
import { arrayOf, shape, string } from "prop-types";
import getContentProps from "@insightfulscience/contentful/utils/getContentProps";
import RichTextLink from "@insightfulscience/contentful/ui/RichTextLink";
import Image from "@insightfulscience/atomic-react/Image";
import { Col } from "@insightfulscience/atomic-react/Grid";

const BlockFeaturedContentItems = ({ items = [] }) => {
	return (
		<>
			{items &&
				items.map(item => {
					const { slug = "", externalUrl = "", type, image, sys_type: sysType } = item;
					const contentProps = getContentProps(sysType, type);
					return (
						<Col md={3} key={sysType.id}>
							<RichTextLink
								href={
									contentProps && contentProps.alias ? `${contentProps.alias}/${slug}` : externalUrl
								}
							>
								<Image src={image} isBaseUrl={false} />
							</RichTextLink>
						</Col>
					);
				})}
		</>
	);
};

BlockFeaturedContentItems.propTypes = {
	items: arrayOf(
		shape({
			sys_type: string,
			slug: string,
			externalUrl: string,
			teaser: string,
			description: string,
			title: string,
			image: string,
			type: string,
			lead: string,
			articleType: string,
			thumbnail: string,
		}),
	),
};

export default BlockFeaturedContentItems;
