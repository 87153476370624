import React from "react";
import Header from "@insightfulscience/atomic-react/Header";
import Button from "@insightfulscience/atomic-react/Button";
import { bool } from "prop-types";
import { useLocale } from "@insightfulscience/smart-react/i18";
import { Col, Row } from "@insightfulscience/atomic-react/Grid";
import Navbar from "@insightfulscience/atomic-react/Navbar";
import NavbarDropdown from "@insightfulscience/atomic-react/NavbarDropdown";
import NavbarDropdownTitle from "@insightfulscience/atomic-react/NavbarDropdown/Title";
import NavbarDropdownMenu from "@insightfulscience/atomic-react/NavbarDropdown/Menu";
import NavbarItem from "@insightfulscience/atomic-react/Navbar/Item";
import NavbarItemLink from "@insightfulscience/atomic-react/Navbar/Link";
import NextLink from "@insightfulscience/smart-react/NextLink";
import prerenderedLogo from "../PrerenderedLogo";
import { HeaderTopBar } from "../HeaderTopBar";
import ProductsDropdown from "../SiteHeader/ProductsDropdown";
import ResourcesDropdown from "../SiteHeader/ResourcesDropdown";
import SupportDropdown from "../SiteHeader/SupportDropdown";
import SignInDropdown from "../SiteHeader/SignInDropdown";
import routes from "../routes";

const BiologicsHeader = ({ isAuthorized, showTopBar = true, ...props }) => {
	const [t] = useLocale("header");

	return (
		<>
			{showTopBar && <HeaderTopBar />}
			<Header logo={prerenderedLogo} isBorderBottomNone {...props}>
				<Row sizing={{ w: "full" }} middle>
					<Col lg="auto" utility={{ justify: "center" }}>
						<Navbar nav="primary" type={1}>
							<NavbarDropdown isMegaMenu>
								<NavbarDropdownTitle>{t("PRODUCTS")}</NavbarDropdownTitle>
								<NavbarDropdownMenu isList={false}>
									<ProductsDropdown />
								</NavbarDropdownMenu>
							</NavbarDropdown>
							<NavbarDropdown isMegaMenu>
								<NavbarDropdownTitle>{t("RESOURCES")}</NavbarDropdownTitle>
								<NavbarDropdownMenu isList={false}>
									<ResourcesDropdown />
								</NavbarDropdownMenu>
							</NavbarDropdown>
							<NavbarDropdown isMegaMenu>
								<NavbarDropdownTitle>{t("SUPPORT")}</NavbarDropdownTitle>
								<NavbarDropdownMenu isList={false}>
									<SupportDropdown />
								</NavbarDropdownMenu>
							</NavbarDropdown>
							<NavbarItem>
								<NextLink Component={NavbarItemLink} href={routes.pricingBiologics}>
									{t("HOW_TO_BUY")}
								</NextLink>
							</NavbarItem>
						</Navbar>
					</Col>
					<Col lg="shrink">
						<Navbar nav="secondary" type={1}>
							<NavbarDropdown isSecondaryNav isMegaMenu>
								<NavbarDropdownTitle>{t("SIGNIN")}</NavbarDropdownTitle>
								<NavbarDropdownMenu isList={false}>
									<SignInDropdown />
								</NavbarDropdownMenu>
							</NavbarDropdown>
							<NavbarItem secondary className="secondary-nav__item-trial">
								<NextLink Component={Button.Link} href={routes.getStartedBiologics} size="s">
									{t("GET_STARTED")}
								</NextLink>
							</NavbarItem>
						</Navbar>
					</Col>
				</Row>
			</Header>
		</>
	);
};

BiologicsHeader.propTypes = {
	isAuthorized: bool.isRequired,
	showTopBar: bool,
};

export default BiologicsHeader;
