import contentfulProductFactory, {
	fetchProps,
	fetchSlugs,
} from "@insightfulscience/contentful/Apps/Product";
import Page from "../../contentful/Apps/Product/Page";
import resources from "../../../locales";
import ProductCopy from "../../contentful/Apps/components/ProductCopy";
import ProductFeatureContent from "../../contentful/Apps/Product/components/BlockFeaturedContent";
import { REVALIDATE_TIME, contentfulProps } from "../../config";

const productLayout = ({ children }) => children;

const ContentfulProductApp = contentfulProductFactory({
	Layout: productLayout,
	Page,
	contentfulProps,
	pageProps: {
		resources,
		brandCode: "gn",
		siteDomain: "https://www.geneious.com",
		components: {
			blockCopy: ProductCopy,
			blockFeaturedContent: ProductFeatureContent,
		},
	},
	mode: "isr",
});

export const getStaticPaths = async () => {
	const slugs = await fetchSlugs(contentfulProps);

	return {
		paths: slugs.map(slug => ({
			params: { slug },
		})),
		fallback: "blocking",
	};
};

export const getStaticProps = async ({ params: { slug } }) => {
	const props = await fetchProps(contentfulProps, slug);

	return props?.product
		? {
				props,
				revalidate: REVALIDATE_TIME,
		  }
		: { notFound: true };
};

export default ContentfulProductApp;
