import React, { Fragment } from "react";
import { Col, Row } from "@insightfulscience/atomic-react/Grid";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { bool, number, object, oneOf, string } from "prop-types";
import colToRows from "@insightfulscience/contentful/utils/colToRows";
import Button from "@insightfulscience/atomic-react/Button";
import Block from "@insightfulscience/atomic-react/Block";
import richTextTypes from "@insightfulscience/contentful/richTextTypes/default";
import Heading from "@insightfulscience/atomic-react/Heading";
import Paragraph from "@insightfulscience/atomic-react/Paragraph";
import { groupByHeading } from "../../../utility/groupByHeading";

const ProductCopy = ({
	id,
	blockTitle,
	blockSubtitle,
	text: { content = [] },
	numberOfColumns,
	numberOfQuestions = 1,
	ctaLink,
	ctaText,
	backgroundColor,
	alignment = "Left",
	internalSlug,
	faqCopy = false,
}) => {
	const borderStyles = !faqCopy ? { border: { left: { width: 1, color: "black--20" } } } : {};
	const numberOfBlocks = faqCopy ? numberOfQuestions : 1;
	return (
		<Block id={internalSlug} utility={{ bgColor: backgroundColor }}>
			<Heading Tag="h2" sx={{ fontSize: "xl", textAlign: "left" }}>
				{blockTitle}
			</Heading>
			<Paragraph sx={{ fontSize: "l", textAlign: "left" }} spacing={{ mt: 4 }}>
				{blockSubtitle}
			</Paragraph>
			<Block spacing={{ mt: 4 }}>
				{colToRows(numberOfColumns, groupByHeading(content, numberOfBlocks)).map(
					(column, rowKey) => (
						<Row
							key={`${id}-row-${rowKey}`} // eslint-disable-line react/no-array-index-key
							mx={numberOfColumns !== 1}
							my
						>
							{column.map((items, colKey) => (
								<Col
									key={`${id}-row-${rowKey}-col-${colKey}`} // eslint-disable-line react/no-array-index-key
									md={12 / numberOfColumns}
									spacing={{ md: { px: 3 }, my: 8 }}
									utility={{ lg: { ...borderStyles } }}
									sx={{ textAlign: alignment === "Center" ? "center" : "left" }}
								>
									{items.map((item, key) => (
										<Fragment
											key={`${id}-row-${rowKey}-col-${colKey}-${key}`} // eslint-disable-line react/no-array-index-key
										>
											{documentToReactComponents(item, richTextTypes)}
										</Fragment>
									))}
								</Col>
							))}
						</Row>
					),
				)}
			</Block>
			{ctaLink && ctaText && (
				<Row spacing={{ mt: 10 }}>
					<Col center>
						<Button.Link href={ctaLink} styleType="secondary" size="s" isPreLine>
							{ctaText}
						</Button.Link>
					</Col>
				</Row>
			)}
		</Block>
	);
};

ProductCopy.propTypes = {
	internalSlug: string.isRequired,
	id: string,
	alignment: oneOf(["Left", "Center"]),
	text: object, // eslint-disable-line react/forbid-prop-types
	blockTitle: string,
	blockSubtitle: string,
	numberOfColumns: number,
	numberOfQuestions: number,
	backgroundColor: string,
	ctaText: string,
	ctaLink: string,
	faqCopy: bool,
};

export default ProductCopy;
