import React from "react";
import { array, elementType, shape, string } from "prop-types";
import Block from "@insightfulscience/atomic-react/Block";
import { Row } from "@insightfulscience/atomic-react/Grid";
import { mapItems } from "@insightfulscience/contentful/Apps/components/FeaturedContentBlock/mapper";
import Heading from "@insightfulscience/atomic-react/Heading";
import Paragraph from "@insightfulscience/atomic-react/Paragraph";
import BlockFeaturedContentItems from "./Items";

const BlockFeaturedContent = ({ name, subname, contentReference = [], components, ...props }) => {
	return (
		<Block>
			{name ? (
				<Heading Tag="h2" sx={{ fontSize: "xl", textAlign: "left" }}>
					{name}
				</Heading>
			) : null}
			{subname ? (
				<Paragraph sx={{ textAlign: "left", fontSize: "l" }} spacing={{ mt: 4 }}>
					{subname}
				</Paragraph>
			) : null}
			<Row mx my spacing={{ mt: 4 }}>
				<BlockFeaturedContentItems items={mapItems(contentReference)} {...props} isImgFluid />
			</Row>
		</Block>
	);
};

BlockFeaturedContent.propTypes = {
	id: string.isRequired,
	name: string.isRequired,
	subname: string,
	contentReference: array, // eslint-disable-line react/forbid-prop-types
	imgClassName: string,
	components: shape({
		sectionTemplate: elementType,
	}),
};

export default BlockFeaturedContent;
