import React from "react";
import Hero from "@insightfulscience/atomic-react/Hero";
import HeroTitle from "@insightfulscience/atomic-react/Hero/Title";
import HeroDescription from "@insightfulscience/atomic-react/Hero/Description";
import HeroAction from "@insightfulscience/atomic-react/Hero/Action";
import { HeroCol, HeroRow } from "@insightfulscience/atomic-react/Hero/Grid";
import HeroButtonLink from "@insightfulscience/atomic-react/Hero/ButtonLink";
import { bool, oneOf, shape, string } from "prop-types";
import { Col, Row } from "@insightfulscience/atomic-react/Grid";
import Image from "@insightfulscience/atomic-react/Image";
import { classes } from "@insightfulscience/atomic-react/utils/classes";
import WistiaVideoEmbed from "@insightfulscience/contentful/ui/WistiaVideoEmbed";
import { useLocale } from "@insightfulscience/smart-react/i18";
import Inline from "@insightfulscience/atomic-react/Inline";

const fileType = shape({
	fields: shape({
		title: string,
		description: string,
		file: shape({
			url: string,
			fileName: string,
			contentType: string,
		}),
	}),
});

const HeroInfo = ({
	title,
	lead,
	primaryCtaLink,
	primaryCtaText,
	secondaryCtaLink,
	secondaryCtaText,
	isLeft,
	fontColor = "black",
}) => {
	const isHasActions = (primaryCtaText && primaryCtaLink) || (secondaryCtaText && secondaryCtaLink);

	return (
		<>
			<HeroTitle sx={{ fontColor }}>{title}</HeroTitle>
			{lead && <HeroDescription sx={{ fontColor }}>{lead}</HeroDescription>}
			{isHasActions && (
				<HeroAction center={!isLeft}>
					<HeroRow>
						{primaryCtaText && primaryCtaLink && (
							<HeroCol>
								<HeroButtonLink href={primaryCtaLink} styleType="primary">
									{primaryCtaText}
								</HeroButtonLink>
							</HeroCol>
						)}
						{secondaryCtaText && secondaryCtaLink && (
							<HeroCol>
								<HeroButtonLink
									href={secondaryCtaLink}
									styleType={fontColor === "white" ? "light" : "primary"}
									isOutline
								>
									{secondaryCtaText}
								</HeroButtonLink>
							</HeroCol>
						)}
					</HeroRow>
				</HeroAction>
			)}
		</>
	);
};

HeroInfo.propTypes = {
	title: string,
	lead: string,
	primaryCtaLink: string,
	primaryCtaText: string,
	secondaryCtaLink: string,
	secondaryCtaText: string,
	isLeft: bool,
	fontColor: string,
};

const HeroBlock = props => {
	const {
		backgroundColor,
		backgroundImage,
		foregroundImage,
		foregroundVideo,
		alignment,
		badge = false,
		biologicsPage = false,
		fontColor = "black",
	} = props;
	const isVideoExist = !!foregroundVideo?.fields?.videoUrl;
	const isImgExist = !!foregroundImage?.fields?.file?.url;
	const isNeedShowRightCol = isVideoExist || isImgExist;
	const isLeft = alignment === "Left";
	const rightColumnConf = isLeft ? { md: 6, lg: 7 } : { md: 12, lg: 12 };
	const leftColumnConf = isLeft ? { md: 6, lg: 5 } : { md: 12, lg: 12 };
	const [t] = useLocale("product");
	return (
		<Hero
			spacing={{ py: 18 }}
			backgroundImage={backgroundImage?.fields?.file?.url}
			utility={{ bgColor: backgroundColor }}
		>
			{badge && (
				<Row>
					<Col shrink middle>
						<Inline
							utility={{
								display: "flex",
								radius: "1000px",
								align: { items: "center" },
								bgColor: fontColor === "white" ? "white--20" : "4",
							}}
							spacing={{ px: 5, py: 3, mb: 4 }}
							sx={{
								fontSize: "m",
								fontWeight: "600",
								fontColor: fontColor === "white" ? "white" : "black",
							}}
						>
							{biologicsPage ? (
								<>
									{t("BIOLOGICS_BADGE_TEXT")}{" "}
									<Image spacing={{ ml: 2 }} src={t("BIOLOGICS_BADGE_ICON")} isBaseUrl />
								</>
							) : (
								<>
									{t("PRIME_BADGE_TEXT")}{" "}
									<Image spacing={{ ml: 2 }} src={t("PRIME_BADGE_ICON")} isBaseUrl />
								</>
							)}
						</Inline>
					</Col>
				</Row>
			)}
			<Row mx utility={{ align: { items: "center" } }}>
				<Col
					{...(isNeedShowRightCol && leftColumnConf)}
					smOrder={1}
					mdOrder={0}
					className={classes(isLeft && "text-left")}
					spacing={{ mb: isNeedShowRightCol ? 10 : 0, sm: { mb: 0 } }}
				>
					<HeroInfo {...props} isLeft={isLeft} />
				</Col>
				{isNeedShowRightCol && (
					<Col {...rightColumnConf} smOrder={0} mdOrder={1} spacing={{ sm: { mb: 10 } }}>
						{isVideoExist ? (
							<WistiaVideoEmbed url={foregroundVideo.fields.videoUrl} />
						) : (
							<Image src={foregroundImage?.fields?.file?.url} isBaseUrl={false} />
						)}
					</Col>
				)}
			</Row>
		</Hero>
	);
};

HeroBlock.propTypes = {
	alignment: oneOf(["Left", "Center"]),
	backgroundColor: string,
	backgroundImage: fileType,
	foregroundImage: fileType,
	foregroundVideo: shape({
		fields: shape({
			videoUrl: string,
		}),
	}),
	title: string,
	lead: string,
	primaryCtaLink: string,
	primaryCtaText: string,
	secondaryCtaLink: string,
	secondaryCtaText: string,
	badge: bool,
	biologicsPage: bool,
	fontColor: string,
};

export default HeroBlock;
